function shuffleArray(array) {
  let i;
  for (i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp1 = array[i];
    array[i] = array[j];
    array[j] = temp1;
  }
  return array;
}

export default function mathGenerator() {
  const difficulty0 = [
    ["2-1", 1],
    ["3-0", 3],
    ["3-1", 2],
    ["3-2", 1],
    ["3-3", 0],
    ["4-1", 3],
    ["4-2", 2],
    ["4-3", 1],
    ["4-4", 0],
    ["5-0", 5],
    ["5-1", 4],
    ["5-2", 3],
    ["5-3", 2],
    ["5-4", 1],
    ["5-5", 0],
    ["3+0", 3],
    ["3+1", 4],
    ["3+2", 5],
    ["3+3", 6],
    ["3+4", 7],
    ["3+5", 8],
    ["3+6", 9],
    ["4+0", 4],
    ["4+1", 5],
    ["5+2", 7],
    ["5+4", 9],
    ["6+2", 8],
    ["3+6", 9],
    ["8+1", 9],
    ["9+0", 9],
    ["7+1", 8],
    ["9-3", 6],
    ["8-4", 4],
    ["6-4", 2],
    ["9-8", 1],
    ["8-7", 1],
    ["6-6", 0],
    ["6-5", 1],
    ["7+2", 9],
    ["6-3", 3],
  ];

  const difficulty1 = [
    ["2+2+2", 6],
    ["3+3+3", 9],
    ["1+1-1", 1],
    ["5+5-5", 5],
    ["8+8-8", 8],
    ["6-6+6", 6],
    ["4-4+4", 4],
    ["2-2+5", 5],
    ["4+3-3", 4],
    ["0+2+3", 5],
    ["3+5-6", 2],
    ["4+5-7", 2],
    ["6-3+5", 8],
    ["7-4+5", 8],
    ["8-5+6", 9],
    ["1-1+6", 6],
    ["3-2+8", 9],
    ["5-4+6", 7],
    ["9-8+7", 8],
    ["9-6+6", 9],
    ["9-4+3", 8],
    ["9-4+0", 5],
    ["8-6+4", 6],
    ["8-5+1", 4],
    ["8-3+2", 7],
    ["7-0+1", 8],
    ["7-5+6", 8],
    ["7-3+0", 4],
    ["7-1+2", 8],
    ["6-4+2", 4],
    ["6-1-1", 4],
    ["6-0-4", 2],
    ["8-1-0", 7],
    ["6-2-3", 1],
    ["7-5-2", 0],
    ["8-2-2", 4],
    ["9-4-5", 0],
    ["5-3-0", 2],
    ["4-3-1", 0],
    ["2+3+3", 8],
  ];

  const difficulty2 = [
    ["1*3+6", 9],
    ["2*3+2", 8],
    ["2*0+8", 8],
    ["2*4+1", 9],
    ["3*0+6", 6],
    ["3*1+1", 4],
    ["4*0+7", 7],
    ["4*2+1", 9],
    ["0*4+5", 5],
    ["3+3*2", 9],
    ["1+2*0", 1],
    ["2+1*6", 8],
    ["1+1*7", 8],
    ["0+4*2", 8],
    ["9+7*0", 9],
    ["5+1*1", 6],
    ["4+2*2", 8],
    ["3+3*2", 9],
    ["2+2*2", 6],
    ["0+7*0", 0],
    ["3*5-7", 8],
    ["3*3-4", 5],
    ["3*4-8", 4],
    ["3*2-1", 5],
    ["4*2-1", 7],
    ["4*3-6", 6],
    ["3*2-0", 6],
    ["5*2-7", 3],
    ["6*2-6", 6],
    ["6*3-9", 9],
    ["3*3-9", 0],
    ["9-2*3", 3],
    ["8-3*2", 2],
    ["7-8*0", 7],
    ["7-6*1", 1],
    ["6-4*1", 2],
    ["5-2*0", 5],
    ["5-2*2", 1],
    ["4-6*0", 4],
    ["0-9*0", 0],
  ];

  const difficulty3 = [
    ["2*4-2*3", 2],
    ["3*3-4*2", 1],
    ["5*2-2*4", 2],
    ["5*3-7*1", 8],
    ["6*2-3*3", 3],
    ["4*2-9*0", 8],
    ["5*2-3*1", 7],
    ["2*2-1*1", 3],
    ["3*2-6*1", 0],
    ["7*2-4*2", 6],
    ["1*6-2*2", 2],
    ["1*9-3*3", 0],
    ["2*8-1*9", 7],
    ["8*2-4*3", 4],
    ["7*2-6*2", 2],
    ["1*3+2*3", 9],
    ["2*2+1*5", 9],
    ["1*7+0*6", 7],
    ["5*1+2*2", 9],
    ["2*3+1*1", 7],
    ["2*3*3-9", 9],
    ["1*6*2-7", 5],
    ["1*5*2-4", 6],
    ["2*4*2-7", 9],
    ["3*4*1-8", 4],
    ["0+2*4*1", 8],
    ["1+3*4-7", 6],
    ["4+3*3-9", 4],
    ["0+2*8-7", 9],
    ["7+8*1-9", 6],
    ["5+2*4-6", 7],
    ["9-2*4+7", 8],
    ["8-4*2+5", 5],
    ["7-2*2+0", 3],
    ["6-1*5+3", 4],
    ["5-2*2+7", 8],
    ["5-4*1+3", 4],
    ["4-1*2+7", 9],
    ["3-7*0+2", 5],
    ["3-6*0-2", 1],
  ];

  const difficulty4 = [
    ["3*6/2-2", 7],
    ["3*6/3+2", 8],
    ["2*6/3+5", 9],
    ["2*8/4+1", 5],
    ["9*2/3-5", 1],
    ["7*2/2-0", 7],
    ["5*3/5+6", 9],
    ["9-2*3/6", 8],
    ["9-4*4/8", 7],
    ["8-3*4/2", 2],
    ["8-4*4/2", 0],
    ["7-6/2*1", 4],
    ["7-9/3*0", 7],
    ["7-8/4*2", 3],
    ["6-9*2/6", 3],
    ["5-6*2/4", 2],
    ["6/3*8-9", 7],
    ["8/2*2-7", 1],
    ["4/4*8-6", 2],
    ["9/3+7-4", 6],
    ["9/3*4-3", 9],
    ["8/2*4-9", 7],
    ["8/4+2*2", 6],
    ["6/2+9*0", 3],
    ["4/1+4-8", 0],
    ["3*1+4/2", 5],
    ["2*3-9/9", 5],
    ["3*2+6/3", 8],
    ["7*1-8/4", 5],
    ["0/4+8*1", 8],
    ["3*4*2/8", 3],
    ["6*7*0/3", 0],
    ["1*9*2/3", 6],
    ["2*2*2/2", 4],
    ["4*5/2-2", 8],
    ["4*3/4*1", 3],
    ["4*1*3-7", 5],
    ["9-3*4/6", 7],
    ["9-2*8*0", 9],
    ["1+3*4/6", 3],
  ];

  return [
    shuffleArray(difficulty0),
    shuffleArray(difficulty1),
    shuffleArray(difficulty2),
    shuffleArray(difficulty3),
    shuffleArray(difficulty4),
  ];
}
